.title{
 font-size: xx-large;
 color: white;
 margin: 40px 0;
 cursor: pointer;
 text-decoration: underline;
}

.results{
    margin: 20px 0;
}