table,td,th{
    border: 1px solid white;
    border-collapse: collapse;
    text-align: center;
  }

  th{
    color: white;
  }

  td{
    color: white;
  }

  table{
    table-layout: fixed ; 
    width: 70%;
  }

  .first-table, .second-table, .third-table, .fourth-table, .fifth-table{
    width: 100%;
    display: flex;
    justify-content: center; 
 }