
.footer {
  background-color: black;
  height: 160px;
  position: bottom;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 20px;
  color: white;
}

.footer-image{
  height: 80px;
}

.add-heading{
  font-size: 21px;
  font-weight: bold;
  margin-top: -25px;
}

.add-content {
  margin-top: 12px;
  margin-bottom: -17px;
  font-size: medium;
  color: white;
}

.map-link {
  color: blue;
  text-decoration: underline;
}

.dev-heading{
  font-size: 21px;
  font-weight: bold;
  margin-top: -5px;
}

.bhagya-link {
  margin-right: 180px;
  margin-top: 17px;
}

.bhagya-link a {
  color: lightblue;
  text-decoration: none;
}

.pranav-link {
  margin-left: 230px;
  margin-top: -18px;
}

.pranav-link a {
  color: lightblue;
  text-decoration: none;
}
.rupali-link {
  margin-right: 180px;
  margin-top: 18px;
}

.rupali-link a {
  color: lightblue;
  text-decoration: none;
}

.shingini-link {
  margin-left: 235px;
  margin-top: -20px;
}

.shingini-link a {
  color: lightblue;
  text-decoration: none;
}

.aman-link {
  margin-right: 200px;
  margin-top: 18px;
}

.aman-link a {
  color: lightblue;
  text-decoration: none;
}

.govind-link {
  margin-left: 230px;
  margin-top: -20px;
}

.govind-link a {
  color: lightblue;
  text-decoration: none;
}


