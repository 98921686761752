.past-editions{
    height: 100vh;
    overflow: scroll;
}

.logo{
    position: fixed;
    left: 20px;
    top: 20px;
    max-width: 10%;
}

#past-edition-btn {
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: #7846d5;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
}

#past-edition-btn:hover {
    background-color: #b683ec;
}

.prev-editions{
    margin-top: 80px;
}