* {box-sizing: border-box}
body {font-family: Verdana, sans-serif; margin:0}
.mySlides {display: none}
img {vertical-align: middle;}

.slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;
}

.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

.prev:hover, .next:hover {
  background-color: rgba(0,0,0,0.8);
}

.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}


.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 1s ease;
}

.active, .dot:hover {
  background-color:darkorchid
}

.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}

@media only screen and (max-width: 300px) {
  .prev, .next,.text {font-size: 11px}
}

.chief{
  display:flex;
  justify-content:center;
  align-items:flex;
}

.chief-img{
  height:400px;
  width:400px;
  padding-top: 40px;
}

.chief-text{
  padding: 25px 100px 50px 100px;
  text-align: center;
  color: white;
}
.main_img{
  height: 400px;
  width: 500px;
}

.carousel{
  cursor: pointer;
  border-radius: 70%;
  background-color: white;
  margin: 15px;
}

.dots{
  align-items: center;
  padding-top: 20px;
}
.dots1{
  padding-left: 20px;
  padding-right: 20px;
}


.carousel-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
}

.names{
  color: white;
  font-size: large;
  font-weight: bold;
  margin: 10px 0;
}

.arbiter-name-link{
  cursor: pointer;
}