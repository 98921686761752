
.logo{
    position: fixed;
    left: 20px;
    top: 20px;
    max-width: 10%;
}

/* Main content styles */
main {
    padding: 20px;
    color: #fff;
    display:  flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh)
}

main img {
    max-width: 300px;
    display: block;
    float: right;
}

main div {
    margin-top: 100px; /* Adjust as needed based on the image size */
}

main p {
    margin-top: 20px;
    text-align: left;
    font-size: 1.2em;
    font-family: Comic;
}
footer {
    background-color: #333;
    padding: 10px;
    text-align: center;
    color: #fff;
}
#rapid-chess {
    text-transform: uppercase;
    text-align: left;
    font-size: 6em;
    margin-top: 1px;
    text-shadow: 5px 5px 7px #4843ee;
    font-family:  Times;
}

#fide-tournament {
    text-align: left;
    font-size: 3em;
    margin-top: 1px;
    text-shadow: 5px 5px 7px #4843ee;
    font-family: Optima;
}


#past-edition-btn {
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: #7846d5;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
}

#past-edition-btn:hover {
    background-color: #b683ec;
}

.chess-piece {
    /* max-width: 100%;
    float: right;
    margin-right: 1%;
    margin-top: -150px;  */
    filter: invert(97%) sepia(100%) saturate(26%) hue-rotate(325deg) brightness(105%) contrast(100%);
}

.register-here{
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #7846d5;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
}

.past-edition-btn:hover {
    background-color: #b683ec;
}