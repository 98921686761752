.App {
  text-align: center;
  font-family: sans-serif;
  background: radial-gradient(circle, #332063, #3b247a, #18102e);
  /* background-image: url('./assets/images/bg3.avif'); */
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

::-webkit-scrollbar{
  display: none;
}

html {
  
  /* background-color: rgb(26, 5, 142); */
}

body {
  margin: 0;
  padding: 0;
}
